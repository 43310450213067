import { makeObservable, runInAction } from 'mobx'

import makeQuery from 'api/makeQuery'
import pick from 'util/pick'
import User from 'singletons/User'

class MedicalHistory {
  constructor(root, valuesObj) {
    Object.assign(
      this,
      pick(valuesObj, [
        'heightFt',
        'heightIn',
        'weightLbs',
        'allergiesCompleted',
        'medicationsCompleted',
        'conditionsCompleted',
        'familyHistoryCompleted',
        'primaryCareCompleted',
        'medications',
        'lifestyle',
        'conditions',
        'other',
      ]),
    )
    this.root = root
    this.currentConditions = valuesObj.conditions
    this.currentOther = valuesObj.other
    this.otherTextField = ''
    makeObservable(this, {
      conditions: true,
      other: true,
      addOther: true,
      removeOther: true,
      currentOther: true,
      currentConditions: true,
      addCondition: true,
      addTypeCondition: true,
      removeCondition: true,
      removeTypeCondition: true,
      submit: true,
      medications: true,
      deleteMedication: true,
      saveMedication: true,
      otherTextField: true,
      setOtherTextField: true,
      resetForm: true,
    })
  }

  destroy() {}

  async submit() {
    const response = await makeQuery('putMedicalHistoryV2', {
      memberId: this.root.member.memberId,
      medicalHistory: {
        conditions: this.conditions,
        memberId: this.root.member.memberId,
        other: this.other,
        heightIn: this.heightIn,
        heightFt: this.heightFt,
        weightLbs: this.weightLbs,
        allergiesCompleted: this.allergiesCompleted,
        medicationsCompleted: this.medicationsCompleted,
        conditionsCompleted: this.conditionsCompleted,
        familyHistoryCompleted: this.familyHistoryCompleted,
        primaryCareCompleted: this.primaryCareCompleted,
        lifestyle: this.lifestyle,
        medications: this.medications,
      },
    })
    runInAction(() => {
      this.currentConditions = response.conditions
      this.currentOther = response.other
    })
  }

  resetForm() {
    this.other = [...this.currentOther]
    this.conditions = [...this.currentConditions]
    this.otherTextField = ''
  }

  addCondition(condition) {
    this.conditions.push({
      condition: condition.condition,
      conditionId: condition.conditionId,
      name: condition.name,
      value: true,
    })
  }

  addTypeCondition(condition, type) {
    this.conditions.push({
      condition: condition.condition,
      conditionId: condition.conditionId,
      name: type.name,
      value: true,
    })
  }

  removeCondition(condition) {
    const index = this.conditions.findIndex((_condition) => _condition.name === condition.name)
    if (index !== -1) this.conditions.splice(index, 1)
  }

  removeTypeCondition(type) {
    const index = this.conditions.findIndex((condition) => condition.name === type.name)
    if (index !== -1) this.conditions.splice(index, 1)
  }

  addOther() {
    this.other.push({ value: this.otherTextField })
    runInAction(() => {
      this.otherTextField = ''
    })
  }

  removeOther(value) {
    const index = this.other.findIndex((condition) => condition.value === value)
    if (index !== -1) this.other.splice(index, 1)
  }

  setOtherTextField(text) {
    this.otherTextField = text
  }

  async saveMedication(medicationId, medication) {
    const isV2 = User.isMedicationSafteyPhase1Enabled

    let response
    if (medicationId) {
      const queryKey = isV2 ? 'putMedicationsV2' : 'putMedications'

      response = await makeQuery(queryKey, {
        medication,
        memberId: this.root.member.memberId,
        medicationId,
      })
    } else {
      const queryKey = isV2 ? 'postMedicationsV2' : 'postMedications'

      response = await makeQuery(queryKey, {
        medication,
        memberId: this.root.member.memberId,
      })
    }
    runInAction(() => {
      this.medications = response.medications
    })
  }

  // TODO - remove ability to delete medication once pwMedicationSafteyPhase1 FF is deprecated
  async deleteMedication(medicationId) {
    const response = await makeQuery('deleteMedications', {
      medicationId: medicationId,
      memberId: this.root.member.memberId,
    })
    runInAction(() => {
      this.medications = response.medications
    })
  }
}

export default MedicalHistory
